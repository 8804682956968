import React from "react"
import SEO from "../components/seo"

import Layout from "../layout/index"
import RoomIcon from '@material-ui/icons/Room'
import LocationStyles from "../styles/location.module.scss"

import storeImg from "../images/landing/treats_by_eunice_store.jpg"

const LocationPage = () => (

	<div id="wrapper" class="main">
		<div id="main">
		<SEO title="Location" />
			<div class="inner">
				<h1 class="h1">Hours &amp; Location</h1>
				<h3> Address</h3>
				455-3 Dogok-dong, Gangnam-gu, Seoul, South Korea<br />
				서울 강남구 도곡동 455-3
				<h3>Contact</h3>
				<p>070-8835-2777</p>
				
				<h3>Cafe opening hours</h3>
				<p>
					<strong>Thursday</strong>12pm - 7pm <br/>
					<strong>Friday</strong>12pm - 7pm <br/>
					<strong>Saturday</strong>12pm - 7pm <br/>
				</p>
				
				<div style={{}}>
					<a href="https://goo.gl/maps/uUQuT7BMk8x" target="_blank"><RoomIcon />MAP</a>
				</div>
				<br/>
				<br/>
				<img class="locationimage" src={storeImg}/>
				
				
			</div>
		</div>
	</div>
)

export default LocationPage;